import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from './assets/logo/sqwish-white-thick.webp';
import { playgroundClick, accessClick, pricingClick } from './analytics';

const Header: React.FC = () => {
  const location = useLocation();

  const handleLogoClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (location.pathname === '/') {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handlePlaygroundLinkClick = () => {
    playgroundClick();
  };

  const handleAccessLinkClick = () => {
    accessClick('header');
  };

  const handlePricingLinkClick = () => {
    pricingClick();
  };


  return (
    <>
      {location.pathname !== '/access' && location.pathname !== '/playground' && (
        <header className="fixed top-0 w-full left-0 p-4 flex flex-col items-center justify-center z-50">
          <nav className="py-2 px-4 flex justify-between items-center rounded-full w-full max-w-[765px] bg-white/50 lg:-translate-x-[67px] backdrop-blur-lg border border-white/20 shadow-md">
            <div className="flex items-center justify-center gap-0">
              <Link
                to="/"
                onClick={handleLogoClick}
                className="flex items-center justify-center pl-2 pr-2 "
              >
                <img src={logo} alt="Sqwish" width="95" />
              </Link>
              <Link
                to="/"
                className={`hidden sm:flex text-black s-body rounded-[62.5rem] transition-colors duration-300 px-4 py-1 h-10 flex items-center ml-4 justify-center ${location.pathname === '/'
                  ? 'bg-white/50 text-black border border-gray-100 shadow'
                  : 'hover:bg-white/50 hover:shadow cursor-pointer'
                  }`}
              >
                Home
              </Link>
              <Link
                to="/pricing"
                onClick={handlePricingLinkClick}
                className={`hidden sm:flex text-black s-body rounded-[62.5rem] hover:bg-white/50 hover:shadow transition-colors duration-300 px-4 py-1 h-10 flex items-center justify-center ${location.pathname === '/pricing'
                  ? 'bg-white/50 text-black border border-gray-100 shadow'
                  : 'hover:bg-white/50 hover:shadow cursor-pointer'
                  }`}
              >
                Pricing
              </Link>
              <Link
                to="/docs"
                className={`hidden sm:flex text-black s-body rounded-[62.5rem] hover:bg-white/50 hover:shadow transition-colors duration-300 px-4 py-1 h-10 flex items-center justify-center ${location.pathname === '/docs'
                  ? 'bg-white/50 text-black border border-gray-100 shadow'
                  : 'hover:bg-white/50 hover:shadow cursor-pointer'
                  }`}
              >
                Docs
              </Link>
            </div>
            <div className="flex items-center justify-center gap-2">
              <Link
                to="/playground"
                onClick={handlePlaygroundLinkClick}
                className={`bg-white text-black s-body rounded-[62.5rem] hover:bg-gray-100 shadow hover:shadow-md transition-colors duration-300 px-4 py-1 h-10 flex items-center justify-center border border-gray-100 ${location.pathname === '/playground'
                  ? 'invisible'
                  : 'hidden sm:flex'
                  }`}
              >
                Try now
              </Link>
              <Link
                to="/access"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleAccessLinkClick}
                className="bg-black text-white s-body rounded-[62.5rem] hover:bg-black/70 transition-colors duration-300 px-4 py-1 h-10 flex items-center justify-center shadow-[inset_0_2px_4px_rgba(255,255,255,0.05),0_3px_6px_rgba(0,0,0,0.3)] hover:shadow-lg"
              >
                Get access
              </Link>
            </div>
          </nav>
        </header>
      )}
    </>
  );
};

export default Header;
