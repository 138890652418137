import React from 'react';
import overlay from '../assets/backgrounds/noise_overlay-7.webp';

const TermsOfService: React.FC = () => {
  const effectiveDate = 'December 16, 2024';
  const contactEmail = 'contact@sqwish.ai';

  return (
    <div className="text-gray-800 font-sans relative bg-gray-50 min-h-screen">
      {/* Background overlay image */}
      <div
        className="pointer-events-none absolute inset-0"
        style={{
          backgroundImage: `url(${overlay})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundBlendMode: 'overlay',
        }}
      />
      <div className="w-full h-full overflow-y-auto">
        <main className="max-w-3xl mx-auto py-10 px-6 mt-20 relative z-10">
          {/* Main Title */}
          <h1 className="text-4xl font-bold mb-2">Terms of Service</h1>

          {/* Last Updated / Effective Date */}
          <p className="text-sm text-gray-600 italic mb-8">
            Last Updated: {effectiveDate}
          </p>

          {/* Intro Paragraph */}
          <p className="text-base leading-relaxed mb-6">
            Welcome to Sqwish.ai! These Terms of Service (<em>"Terms"</em>) govern
            your access to and use of Sqwish.ai’s real-time prompt compression API
            and related services (the <em>"Service"</em>). By using our Service,
            you agree to be bound by these Terms. If you do not agree to these Terms,
            you may not use the Service.
          </p>

          <hr className="my-8 border-gray-200" />

          {/* Sections */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
            <p className="leading-relaxed">
              By accessing or using the Service, you agree to comply with these Terms.
              If you use the Service on behalf of an organization, you represent and
              warrant that you have the authority to bind that organization to these Terms.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">2. Description of Service</h2>
            <p className="leading-relaxed">
              Sqwish.ai provides a real-time prompt compression API designed to optimize
              and compress textual data. Access to the Service requires the use of API
              keys provided upon registration. The Service may evolve over time with
              new features or modifications.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">3. User Accounts</h2>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed">
              <li>
                <strong>Registration:</strong> You must provide accurate and complete
                information during registration and keep it up to date.
              </li>
              <li>
                <strong>Account Security:</strong> You are responsible for maintaining
                the confidentiality of your API key and account credentials and for all
                activities occurring under your account.
              </li>
              <li>
                <strong>Unauthorized Access:</strong> Notify us immediately if you suspect
                unauthorized access to your account.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">4. Permitted Use and Restrictions</h2>
            <p className="leading-relaxed mb-4">
              <strong>Compliance:</strong> You agree to use the Service in accordance
              with all applicable laws and regulations.
            </p>
            <p className="leading-relaxed">
              <strong>Prohibited Actions:</strong> You may not:
            </p>
            <ul className="list-disc pl-6 space-y-2 mt-2 leading-relaxed">
              <li>Use the Service for unlawful or harmful purposes.</li>
              <li>Reverse engineer, decompile, or attempt to extract the source code of the Service.</li>
              <li>Circumvent any security measures.</li>
              <li>Overburden or interfere with the functionality of the Service.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">5. Fees and Payment</h2>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed">
              <li>
                <strong>Pricing:</strong> Access to certain features of the Service may
                require payment of fees as outlined on our <a href="/pricing" className="underline text-blue-600 hover:text-blue-800">pricing page</a>.
              </li>
              <li>
                <strong>Payment Terms:</strong> Fees are billed on a subscription basis
                or as outlined during account setup.
              </li>
              <li>
                <strong>Refunds:</strong> Except where required by law, fees are non-refundable.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">6. Intellectual Property Rights</h2>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed">
              <li>
                <strong>Ownership:</strong> Sqwish.ai retains all rights, title, and
                interest in the Service, including its underlying software, trademarks,
                and content.
              </li>
              <li>
                <strong>License:</strong> You are granted a limited, non-exclusive,
                non-transferable license to use the Service in accordance with these Terms.
              </li>
              <li>
                <strong>Feedback:</strong> By providing feedback about the Service, you
                grant Sqwish.ai the right to use and incorporate your feedback without obligation.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">7. Your Content</h2>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed">
              <li>
                <strong>Ownership:</strong> You retain ownership of any intellectual
                property rights in the content you upload, submit, or process through the Service.
              </li>
              <li>
                <strong>Usage Rights:</strong> By using the Service, you grant Sqwish.ai
                the right to use, store, and process your content to provide and improve the Service.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">8. Data Handling and Privacy</h2>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed">
              <li>
                <strong>Data Storage:</strong> Processed data may be temporarily stored
                for performance optimization and troubleshooting.
              </li>
              <li>
                <strong>Privacy Policy:</strong> Refer to our <a href="/privacy-policy" className="underline text-blue-600 hover:text-blue-800">Privacy Policy</a> for
                details on how we collect, use, and protect your data.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">9. Limitation of Liability</h2>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed">
              <li>
                <strong>Exclusion of Damages:</strong> To the fullest extent permitted by
                law, Sqwish.ai is not liable for any indirect, incidental, special,
                consequential, or punitive damages, including loss of profits, revenue, or data.
              </li>
              <li>
                <strong>Liability Cap:</strong> Our total liability for any claims arising
                under these Terms is limited to the amount you paid for the Service in
                the 12 months preceding the claim.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">10. Termination</h2>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed">
              <li>
                <strong>By User:</strong> You may terminate your use of the Service
                at any time by ceasing access and use.
              </li>
              <li>
                <strong>By Sqwish.ai:</strong> We may suspend or terminate access to the Service
                if you violate these Terms or engage in harmful activities.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">11. Modifications to the Service and Terms</h2>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed">
              <li>
                <strong>Service Changes:</strong> We may update or modify the Service
                at any time without prior notice.
              </li>
              <li>
                <strong>Term Updates:</strong> We may revise these Terms by posting
                updated terms on our website. Continued use of the Service after such
                changes constitutes acceptance.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">12. Governing Law and Dispute Resolution</h2>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed">
              <li>
                <strong>Governing Law:</strong> These Terms are governed by and construed
                in accordance with the laws of England and Wales.
              </li>
              <li>
                <strong>Dispute Resolution:</strong> Any disputes arising from these Terms
                will be resolved through binding arbitration or as otherwise required by law.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">13. Contact Information</h2>
            <p className="leading-relaxed">
              For any questions or concerns regarding these Terms, please contact us at
              <a href={`mailto:${contactEmail}`} className="underline text-blue-600 hover:text-blue-800 ml-1">
                {contactEmail}
              </a>.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">14. Entire Agreement</h2>
            <p className="leading-relaxed">
              These Terms, along with our Privacy Policy, constitute the entire agreement
              between you and Sqwish.ai regarding your use of the Service.
            </p>
          </section>

          <p className="leading-relaxed">
            Thank you for choosing Sqwish.ai!
          </p>
        </main>
      </div>
    </div>
  );
};

export default TermsOfService;
