import React, { useEffect, useRef, useState } from "react";
import SqwishLogo from "../assets/logo/sqwish-white-thick.webp";
import CheckMark from "./CheckMark";

const features = [
  { name: "Access to Sqwish model", free: "Self-hosted", pro: "API access", enterprise: "API access / PVC" },
  { name: "Pricing model", free: "Free", pro: "20% savings (min $20/mo)", enterprise: "Contract-based" }, //Contact us?
  { name: "Customer support", free: false, pro: true, enterprise: "true (priority)" },
  { name: "Dashboard & reporting", free: false, pro: true, enterprise: true },
  { name: "Model specific compression", free: false, pro: true, enterprise: true },
  { name: "Latest models and updates", free: false, pro: true, enterprise: true },
  { name: "PVC deployment options", free: false, pro: false, enterprise: true },
  { name: "Fine-tuned custom models", free: false, pro: false, enterprise: true },
  { name: "No rate-limits", free: false, pro: false, enterprise: true }
];

const PricingTable: React.FC = () => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [showLeftGradient, setShowLeftGradient] = useState(false);
  const [showRightGradient, setShowRightGradient] = useState(false);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const handleScroll = () => {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;

      // Show left gradient if we are scrolled more than a few pixels from the left
      setShowLeftGradient(scrollLeft > 0);

      // Show right gradient if there's content beyond the right edge
      setShowRightGradient(scrollLeft + clientWidth < scrollWidth - 1);
    };

    // Initial check on mount
    handleScroll();

    scrollContainer.addEventListener("scroll", handleScroll, { passive: true });
    window.addEventListener("resize", handleScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  return (
    <div className="mt-8 w-full px-4 relative">
      {/* Add a hint for scrolling horizontally */}
      <div className="text-center mb-2 text-sm text-gray-700 max-[980px]:block hidden">
        <span className="inline-block bg-medium-blue px-3 py-1 rounded-full">
          Swipe or scroll horizontally to see more &rarr;
        </span>
      </div>

      <div className="relative">
        {/* Left Gradient */}
        {showLeftGradient && (
          <div className="pointer-events-none absolute top-0 left-0 h-full w-8 bg-gradient-to-r from-[#7575F0] to-transparent z-10" />
        )}
        {/* Right Gradient */}
        {showRightGradient && (
          <div className="pointer-events-none absolute top-0 right-0 h-full w-8 bg-gradient-to-l from-[#7575F0] to-transparent z-10" />
        )}

        {/* Scrollable container */}
        <div
          ref={scrollContainerRef}
          className="w-full overflow-x-auto scrollbar scrollbar-thumb-rounded scrollbar-track-rounded scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-400 relative"
        >
          <table className="min-w-[900px] mx-auto border-collapse text-left">
            <thead>
              <tr className="border-b-[1.5px] border-gray-900">
                <th className="py-4 pl-2 font-bold text-black text-base sm:text-lg w-[350px]">
                  Features
                </th>
                <th className="py-4 font-semibold text-black text-base sm:text-lg w-[200px]">
                  <div className="flex flex-row items-center">Free</div>
                </th>
                <th className="py-4 font-semibold text-black text-base sm:text-lg w-[210px]">
                  <div className="flex flex-row items-center">
                    <img src={SqwishLogo} alt="Sqwish Logo" className="w-auto mr-2 h-[30px]" />
                    <span
                      className="text-dark-green font-semibold pb-2 -ml-1"
                      style={{ textShadow: '2px 3px 2px rgba(0,0,0,0.4)' }}
                    >
                      Pro
                    </span>
                  </div>
                </th>
                <th className="py-4 font-semibold text-black text-base sm:text-lg w-[210px]">
                  <div className="flex flex-row items-center">
                    <img src={SqwishLogo} alt="Sqwish Logo" className="w-auto mr-2 h-[30px]" />
                    <span
                      className="text-dark-yellow font-semibold pb-2 -ml-1"
                      style={{ textShadow: '2px 3px 2px rgba(0,0,0,0.4)' }}
                    >
                      Enterprise
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {features.map((feature, index) => (
                <tr
                  key={index}
                  className="border-b-[0.5px] border-gray-900 transition-colors hover:bg-medium-blue"
                >
                  <td className="py-3 pl-2 text-sm sm:text-base text-black font-medium">
                    {feature.name}
                  </td>
                  <td className="py-3 text-sm sm:text-base text-black font-normal">
                    {typeof feature.free === "boolean"
                      ? feature.free && <CheckMark className="h-5 w-5 text-black" />
                      : feature.free}
                  </td>
                  <td className="py-3 text-sm sm:text-base text-black font-normal">
                    {typeof feature.pro === "boolean"
                      ? feature.pro && <CheckMark className="h-5 w-5 text-black" />
                      : feature.pro}
                  </td>
                  <td className="py-3 text-sm sm:text-base text-black font-normal">
                    {typeof feature.enterprise === "boolean" ? (
                      feature.enterprise && <CheckMark className="h-5 w-5 text-black" />
                    ) : typeof feature.enterprise === "string" && feature.enterprise.startsWith("true") ? (
                      <span className="flex items-center">
                        <CheckMark className="h-5 w-5 text-black inline-block mr-1" />
                        {feature.enterprise.replace("true", "").trim()}
                      </span>
                    ) : (
                      feature.enterprise
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PricingTable;
