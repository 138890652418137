import React, { useState, useEffect, useRef } from 'react';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python';
import javascript from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import logoSqwishVertical from '../../assets/logo/sqwish-white-thick-vertical.webp'; // Import the logo
import logoSqwishHorizontal from '../../assets/logo/sqwish-white-thick.webp'; // Import the logo
import { Link } from 'react-router-dom';
import { accessClick } from '../../analytics';
// Register the languages for syntax highlighting
SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('bash', bash);

const atmosphereDarkTheme = {
  ...vs2015,
  'hljs': {
    background: '#0003',
    color: '#C5C8C6',
    fontFamily: '"Fira Code", monospace',
    fontSize: '14px',
    lineHeight: '1.5',
  },
  'hljs-comment': { color: '#98C379' },
  'hljs-keyword': { color: '#C678DD', fontWeight: 'bold' },
  'hljs-string': { color: '#98C379' },
  'hljs-function': { color: '#61AFEF' },
  'hljs-variable': { color: '#E06C75' },
  'hljs-number': { color: '#D19A66' },
  'hljs-attr': { color: '#D19A66' },
  'hljs-class': { color: '#E5C07B' },
  'hljs-operator': { color: '#56B6C2' },
};

const CodeSampleSection: React.FC = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<'python' | 'javascript' | 'curl'>('python');
  const [isWithSqwish, setIsWithSqwish] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const [isAutoToggling, setIsAutoToggling] = useState(true);
  const autoToggleIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isAutoToggling) {
      const toggleWithTimeout = () => {
        const timeout = isWithSqwish ? 8000 : 3000;
        autoToggleIntervalRef.current = setTimeout(() => {
          setIsWithSqwish(prev => !prev);
          toggleWithTimeout(); // Schedule the next toggle
        }, timeout);
      };

      toggleWithTimeout(); // Start the auto-toggling
    }

    return () => {
      if (autoToggleIntervalRef.current) {
        clearInterval(autoToggleIntervalRef.current);
      }
    };
  }, [isAutoToggling, isWithSqwish]);

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsWithSqwish(event.target.checked);
    setIsAutoToggling(false);
    if (autoToggleIntervalRef.current) {
      clearInterval(autoToggleIntervalRef.current);
    }
  };

  const codeStrings = {
    python: {
      with: {
        code: `
from openai import OpenAI
from sqwish import Sqwish

client = OpenAI()
sqwish_client = Sqwish()

input_prompt = "Write a haiku about recursion in programming."
compressed_prompt = sqwish_client.text.compress(input_prompt)

completion = client.chat.completions.create(
    model="gpt-4o-mini",
    messages=[
        {"role": "system", "content": "You are a helpful assistant."},
        {
            "role": "user",
            "content": compressed_prompt.message
        }
    ]
)

print(completion.choices[0].message)
      `,
        highlight: [3, 6, 9]
      },
      without: {
        code: `
from openai import OpenAI


client = OpenAI()


input_prompt = "Write a haiku about recursion in programming."


completion = client.chat.completions.create(
    model="gpt-4o-mini",
    messages=[
        {"role": "system", "content": "You are a helpful assistant."},
        {
            "role": "user",
            "content": input_prompt
        }
    ]
)

print(completion.choices[0].message)
      `,
        highlight: [-1]
      }
    },
    javascript: {
      with: {
        code: `
const { OpenAI } = require('openai');
const { Sqwish } = require('sqwish');

const client = new OpenAI();
const sqwishClient = new Sqwish();

const inputPrompt = "Write a haiku about recursion in programming.";
const compressedPrompt = sqwishClient.text.compress(inputPrompt);

const completion = await client.chat.completions.create({
    model: "gpt-4o-mini",
    messages: [
        {"role": "system", "content": "You are a helpful assistant."},
        {
            "role": "user",
            "content": compressedPrompt.message
        }
    ]
});

console.log(completion.choices[0].message);
      `,
        highlight: [3, 6, 9]
      },
      without: {
        code: `
const { OpenAI } = require('openai');


const client = new OpenAI();


const inputPrompt = "Write a haiku about recursion in programming.";


const completion = await client.chat.completions.create({
    model: "gpt-4o-mini",
    messages: [
        {"role": "system", "content": "You are a helpful assistant."},
        {
            "role": "user",
            "content": inputPrompt
        }
    ]
});

console.log(completion.choices[0].message);
    `,
        highlight: [-1]
      }
    },
    curl: {
      with: {
        code: `
  compressed_prompt=$(curl -X POST "https://api.sqwish.ai/v1/compress" \\
    -H "Content-Type: application/json" \\
    -H "Authorization: Bearer YOUR_SQWISH_API_KEY" \\
    -d '{"text": "Write a haiku about recursion in programming."}' \\
    | jq -r '.message')
  
  curl https://api.openai.com/v1/chat/completions \\
    -H "Content-Type: application/json" \\
    -H "Authorization: Bearer YOUR_OPENAI_API_KEY" \\
    -d '{
      "model": "gpt-4o-mini",
      "messages": [
        {"role": "system", "content": "You are a helpful assistant."},
        {"role": "user", "content": "'"$compressed_prompt"'"}
      ]
    }'
        `,
        highlight: [2, 3, 4, 5, 6]
      },
      without: {
        code: `






  curl https://api.openai.com/v1/chat/completions \\
    -H "Content-Type: application/json" \\
    -H "Authorization: Bearer YOUR_OPENAI_API_KEY" \\
    -d '{
      "model": "gpt-4o-mini",
      "messages": [
        {"role": "system", "content": "You are a helpful assistant."},
        {"role": "user", "content": "Write a haiku about recursion in programming."}
      ]
    }'
        `,
        highlight: [-1]
      }
    }
  };

  const getCodeString = () => {
    return codeStrings[selectedLanguage][isWithSqwish ? 'with' : 'without'].code;
  };

  const codeString = getCodeString();

  const handleCopyCode = () => {
    navigator.clipboard.writeText(codeString);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  const handleSignupLinkClick = () => {
    accessClick('code-sample');
  };

  return (
    <section className="section bg-purple pt-24 lg:pt-16 px-6 pb-24 lg:pb-40 gap-8 lg:gap-16 lg:px-16 -mt-8 lg:-mt-16 items-center rounded-t-3xl lg:rounded-t-[2.5rem] relative" >
      <div className="flex flex-col lg:flex-row items-stretch justify-center max-w-[1580px] mx-auto h-full gap-6">
        <div className="w-full h-full lg:w-1/2 xlg:w-2/3 my-2 lg:mb-0 flex items-center justify-center md:px-16 lg:px-2">
          <div className="w-full h-full max-w-[500px] max-h-[500px] lg:max-w-[600px] lg:max-h-[600px] flex items-center justify-center">
            <div className="relative w-full h-full">
              {/* Extra Tab Management for Python */}
              <div className="flex justify-left max-w-[95%]">
                <div className={`flex space-x-2 bg-dark-purple rounded-t-[20px] p-2 translate-y-[1px] shadow-[0_2px_5px_rgba(255,255,255,0.2),0_3px_3px_rgba(0,0,0,0.0)]`}>
                  {/* <div className="flex flex-col items-center gap-2 pl-2 mt-1 border-r-4 mr-2 pr-4 border-white/20">
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only cursor-pointer"
                        checked={isWithSqwish}
                        onChange={handleToggle}
                      />
                      <div className={`w-12 h-6 rounded-full peer  ${isWithSqwish ? 'bg-[#3A3D41]' : 'bg-white/20'}`}>
                        <div
                          className={`absolute left-1.5 top-1 w-4 h-4 bg-white rounded-full transition-transform ${isWithSqwish ? 'translate-x-5' : ''}`}
                        ></div>
                      </div>
                    </label>
                  </div> */}
                  {[
                    { id: 'python', label: 'Python' },
                    { id: 'javascript', label: 'JavaScript' },
                    { id: 'curl', label: 'curl' },
                  ].map((tab) => (
                    <button
                      key={tab.id}
                      onClick={() => setSelectedLanguage(tab.id as 'python' | 'javascript' | 'curl')}
                      className={`z-10 button-container flex-none relative px-4 py-1 sm:px-6 sm:py-1.5 rounded-[20px] text-[15px] flex items-center ${selectedLanguage === tab.id ? 'current active text-white shadow-[0_2px_2px_rgba(255,255,255,0.5),0_3px_3px_rgba(0,0,0,0.2)] z-0 highlighter absolute bg-white/20 transition-transform duration-300' : 'text-white/80 hover:text-white'}`}
                    >
                      {tab.label}
                    </button>
                  ))}
                </div>
              </div>
              <div className="w-full h-full min-h-[500px] max-w-[95%] lg:max-w-[100%]">

                <div className="w-full h-full relative">
                  {/* Vertical Sqwish - Repositioned */}
                  <div className="absolute bottom-[0px] right-[1px] translate-x-full flex items-center justify-center bg-dark-purple py-2 pl-1 pr-1 text-white/80 flex-none rounded-r-2xl invisible lg:visible shadow-[0_2px_5px_rgba(255,255,255,0.2),0_3px_3px_rgba(0,0,0,0.0)]">
                    <div className="flex flex-col items-center gap-1.5">
                      {/* <span className="mr-2" style={{ writingMode: 'vertical-rl' }}>{isWithSqwish ? 'With Sqwish' : 'Without Sqwish'}</span> */}
                      <div className="flex items-center justify-center ml-0.5">
                        <img
                          src={logoSqwishVertical}
                          alt="Sqwish"
                          className=""
                          style={{ height: '95px', width: 'auto' }}

                        />
                      </div>
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only cursor-pointer"
                          checked={isWithSqwish}
                          onChange={handleToggle}
                        />
                        <div className={`h-[44px] w-[22px] rounded-full peer ${isWithSqwish ? 'bg-white/20 border border-white/20' : 'border border-white/20'}`}>
                          <div
                            className={`absolute left-1 top-[5px] w-[14px] h-[14px] bg-white rounded-full transition-transform duration-300 ${isWithSqwish ? 'translate-y-5' : ''}`}
                          ></div>
                        </div>
                      </label>
                    </div>
                  </div>
                  {/* Horizontal Sqwish - Repositioned */}
                  <div className="absolute bottom-[1px] translate-y-full right-0 flex items-center justify-center bg-dark-purple px-2 pb-1 pt-1 text-white/80 flex-none rounded-b-2xl lg:hidden shadow-[0_2px_5px_rgba(255,255,255,0.2),0_3px_3px_rgba(0,0,0,0.0)]">
                    <div className="flex flex-row items-center gap-1.5">
                      {/* <span className="mr-2" style={{ writingMode: 'vertical-rl' }}>{isWithSqwish ? 'With Sqwish' : 'Without Sqwish'}</span> */}
                      <div className="flex items-center justify-center ml-0.5">
                        <img
                          src={logoSqwishHorizontal}
                          alt="Sqwish"
                          className=""
                          style={{ height: 'auto', width: '95px' }}

                        />
                      </div>
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only cursor-pointer"
                          checked={isWithSqwish}
                          onChange={handleToggle}
                        />
                        <div className={`w-[44px] h-[22px] rounded-full peer ${isWithSqwish ? 'bg-white/20 border border-white/20' : 'border border-white/20'}`}>
                          <div
                            className={`absolute bottom-1 left-[5px] h-[14px] w-[14px] bg-white rounded-full transition-transform duration-300 ${isWithSqwish ? 'translate-x-5' : ''}`}
                          ></div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className={`w-full h-full overflow-x-auto bg-dark-purple rounded-tr-lg rounded-bl-lg shadow-[0_2px_5px_rgba(255,255,255,0.2),0_3px_3px_rgba(0,0,0,0.0)]`}>

                    {/* Copy Button */}
                    <button
                      onClick={handleCopyCode}
                      className={`absolute h-[32px] w-[32px] top-4 right-4 shadow-[0_3px_3px_rgba(255,255,255,0.5),0_3px_3px_rgba(0,0,0,0.2)] z-0 highlighter absolute bg-medium-purple text-white/60 hover:text-white/80 px-2 py-2 rounded-[5px] flex items-center ${isCopied ? 'copied' : ''}`}
                    >
                      {isCopied ? (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`h-[20px] w-[20px] absolute transition-opacity duration-300 ${isCopied ? 'opacity-100' : 'opacity-0'}`} fill="currentColor" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                          <path d="M9 16.2l-3.5-3.5-1.4 1.4L9 19 20.3 7.7l-1.4-1.4z" />
                        </svg>
                      ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115.77 122.88" className={`h-4 w-4 absolute transition-opacity duration-300 ${isCopied ? 'opacity-0' : 'opacity-100'}`} fill="currentColor">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z"
                          />
                        </svg>
                      )}
                    </button>

                    {/* SyntaxHighlighter for code snippet */}
                    <SyntaxHighlighter
                      language={selectedLanguage === 'curl' ? 'bash' : selectedLanguage}
                      style={atmosphereDarkTheme}
                      customStyle={{
                        background: 'transparent',
                        margin: 0,
                        fontFamily: '"Courier New", Courier, "Lucida Console", monospace',
                        fontSize: '15px',
                      }}
                      showLineNumbers
                      wrapLines
                      lineNumberStyle={(lineNumber) => ({
                        color: '#6A737D',
                        fontSize: '12px',
                        textAlign: 'left',
                        paddingLeft: '1em',
                        userSelect: 'none',
                      })}
                      lineProps={(lineNumber) => {
                        const style: React.CSSProperties = {
                          display: 'block',
                          width: '100%',
                          transition: 'background-color 600ms ease-in-out'
                        };
                        if (codeStrings[selectedLanguage][isWithSqwish ? 'with' : 'without'].highlight.includes(lineNumber)) {
                          style.backgroundColor = '#fff3';
                          style.width = '800px';  // Extend the highlight
                          style.marginLeft = '-100px';  // Offset to cover left side
                          style.paddingLeft = '100px';  // Adjust padding to maintain text position
                        }
                        return { style };
                      }}
                    >
                      {codeString}
                    </SyntaxHighlighter>
                  </div>

                  <div className="absolute bottom-[0px] right-[8px] translate-x-full flex items-center justify-center bg-dark-purple py-2 pl-1 pr-1 flex-none invisible lg:visible h-[165px] w-[3px]">
                  </div>
                  <div className="absolute -top-[5px] left-[0px] space-x-2 bg-dark-purple translate-y-[1px] h-[5px] w-[279px] sm:w-[327px]">
                  </div>
                  <div className="absolute bottom-[3px] translate-y-full right-0 flex items-center justify-center bg-dark-purple px-2 flex-none lg:hidden w-[163px] h-[6px]">
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="w-full lg:w-1/2 xlg:w-1/3 lg:mb-8 mt-16 lg:mt-0 flex">
          <div className="relative w-full h-full flex items-center justify-center">
            <div className="pt-20 max-w-3xl w-full mx-auto flex-col items-center text-center lg:pb-8">
              <div className="w-full mx-auto flex flex-col items-center text-center gap-6 max-w-[90%]">
                <h3 className="text-white s-body">GET STARTED IN SECONDS</h3>
                <h2 className="text-white heading-2 w-full max-w-[38rem]">
                  Three lines of code
                </h2>
                <p className="text-white l-body w-full max-w-[38rem]">
                  Sqwish seamlessly integrates into your existing AI stack.
                  Try it now: Get your free API key and see the difference in your next API call.
                </p>
              </div>
              <div className="w-full flex items-center justify-center pt-12">
                <Link
                  to="/access"
                  target="_blank" // Open in new tab
                  rel="noopener noreferrer" // Security measure
                  onClick={handleSignupLinkClick}
                  className="shadow-[inset_0_2px_4px_rgba(255,255,255,0.05),0_4px_8px_rgba(0,0,0,0.3)] m-body px-1 py-0.5 sm:px-2 sm:py-1 rounded-full bg-neutral-900 hover:bg-black/40 transition-colors duration-300 text-white h-[50px] w-full max-w-[250px] flex items-center justify-center"
                >
                  Get access
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div >
    </section >
  );
};

export default CodeSampleSection;
