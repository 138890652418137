import React from 'react';
import overlay from './images/noise_overlay-7.webp';

const PlaygroundTermsAndConditions: React.FC = () => {
  const effectiveDate = '18 December 2024';
  const contactEmail = 'contact@sqwish.ai';

  return (
    <div className="text-gray-800 font-sans relative bg-gray-50 min-h-screen">
      {/* Background overlay image */}
      <div
        className="pointer-events-none absolute inset-0"
        style={{
          backgroundImage: `url(${overlay})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundBlendMode: 'overlay',
        }}
      />
      <div className="w-full h-full overflow-y-auto">
        <main className="max-w-3xl mx-auto py-10 px-6 mt-20 relative z-10">
          {/* Main Title */}
          <h1 className="text-4xl font-bold mb-2">Playground Terms and Conditions</h1>

          {/* Last Updated / Effective Date */}
          <p className="text-sm text-gray-600 italic mb-8">
            Effective Date: {effectiveDate}
          </p>

          {/* Intro Paragraph */}
          <p className="text-base leading-relaxed mb-6">
            Welcome to the Prompt Compression API Playground (<em>"Playground"</em>), provided by Sqwish.ai (<em>"we," "our,"</em> or <em>"us"</em>). Access to and use of the Playground is conditioned on your acceptance of these Terms and Conditions (<em>"Terms"</em>). By using the Playground, you agree to comply with these Terms. If you do not agree, you may not access or use the Playground.
          </p>

          <hr className="my-8 border-gray-200" />

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed">
              <li>You must read, understand, and agree to these Terms before using the Playground.</li>
              <li><strong>If you do not agree to these Terms, you will not be permitted to access the Playground.</strong></li>
              <li>You confirm that you are at least 18 years old (or meet the minimum age requirements in your jurisdiction).</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">2. Data Collection and Usage</h2>
            <p className="leading-relaxed mb-4">
              By accessing or using the Playground, you acknowledge and agree that we may collect, use, and process your data for purposes such as analytics, product improvement, research, and compliance.
            </p>

            <h3 className="font-semibold mb-2">2.1 Types of Data Collected</h3>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>User-Provided Content: Inputs you submit to the Playground (e.g., text prompts, files, metadata).</li>
              <li>Generated Outputs: Outputs produced based on your inputs.</li>
              <li>Account Information: Data provided during registration, including name, email address, and login credentials.</li>
              <li>Technical Data: Device information (browser type, operating system, hardware identifiers), IP address, geolocation data, timestamps, and usage logs.</li>
              <li>Cookies and Similar Technologies: Data collected through cookies for operational and analytics purposes.</li>
              <li>Communications Data: Content of messages and contact details if you communicate with us.</li>
            </ul>

            <h3 className="font-semibold mb-2">2.2 Purpose of Data Usage</h3>
            <p className="leading-relaxed mb-4">
              We collect and process your data to:
            </p>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>Provide, maintain, and improve the Playground and our products.</li>
              <li>Train and enhance our models to improve performance.</li>
              <li>Conduct analytics, monitor usage patterns, and detect misuse.</li>
              <li>Ensure the security and stability of our systems.</li>
              <li>Comply with legal obligations and enforce these Terms.</li>
            </ul>

            <h3 className="font-semibold mb-2">2.3 Right to Request Data Deletion (GDPR Compliance)</h3>
            <p className="leading-relaxed mb-4">
              If any data collected can be directly or indirectly traced back to you (<em>"Personal Data"</em>), you have the right to:
            </p>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>Request access to your Personal Data.</li>
              <li>Request corrections, updates, or deletions of your Personal Data.</li>
            </ul>
            <p className="leading-relaxed">
              To exercise these rights, contact us at <a href={`mailto:${contactEmail}`} className="underline text-blue-600 hover:text-blue-800">{contactEmail}</a> with a detailed request. We will respond promptly and in compliance with applicable laws.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">3. Use of the Playground</h2>

            <p className="leading-relaxed mb-4">The Playground is a free service provided by Sqwish for users to experiment with prompt compression and related functionalities. Access to and use of the Playground are subject to the following terms:
            </p>

            <h3 className="font-semibold mb-2">3.1 License to Use the Playground</h3>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>Limited License: Sqwish grants you a non-exclusive, non-transferable, revocable, and limited right to access and use the Playground for lawful, non-commercial purposes.</li>
              <li>Non-Ownership: Your use of the Playground does not grant you ownership of the Playground or any of its components, including but not limited to code, algorithms, or proprietary technologies.</li>
            </ul>
            <h3 className="font-semibold mb-2">3.2 Permitted Use</h3>

            <p className="leading-relaxed mb-4">You may use the Playground only for the following purposes:</p>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>Exploring and testing the capabilities of prompt compression technologies.</li>
              <li>Providing feedback to Sqwish about the Playground's functionality or performance.</li>
              <li>Conducting personal, non-commercial experimentation within the scope of these Terms.</li>
            </ul>
            <h3 className="font-semibold mb-2">3.3 Prohibited Activities</h3>

            <p className="leading-relaxed mb-4">You agree not to engage in any of the following activities while using the Playground:</p>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>Unlawful Activities: Using the Playground for any illegal purpose or in violation of applicable laws and regulations.</li>
              <li>Interference or Disruption:
                <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
                  <li>Attempting to interfere with the normal operation of the Playground, including deploying malicious code, conducting denial-of-service attacks, or circumventing security measures.</li>
                  <li>Overloading the system with excessive automated requests or scraping data.</li>
                </ul>
              </li>
              <li>Reverse Engineering: Attempting to reverse engineer, decompile, or disassemble any part of the Playground or its underlying technologies.</li>
              <li>Unauthorized Access:
                <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
                  <li>Gaining or attempting to gain unauthorized access to Sqwish systems, user data, or restricted areas of the Playground.</li>
                  <li>Impersonating other users or misrepresenting your identity while interacting with the Playground.</li>
                </ul>
              </li>
              <li>Commercial Exploitation:
                <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
                  <li>Selling, sublicensing, or otherwise exploiting the Playground or its outputs for commercial purposes without explicit authorization from Sqwish.</li>
                  <li>Data Abuse: Using the Playground to collect or process sensitive personal data that violates the privacy rights of individuals or infringes upon intellectual property rights.</li>
                </ul>
              </li>
            </ul>
            <h3 className="font-semibold mb-2">3.4 Monitoring and Enforcement</h3>

            <p className="leading-relaxed mb-4">Sqwish reserves the right to monitor activity on the Playground to ensure compliance with these Terms. Monitoring and enforcement include:</p>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>Monitoring:
                <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
                  <li>Logging usage patterns, IP addresses, and system interactions to detect and prevent misuse.</li>
                  <li>Ensuring fair usage and maintaining system stability through rate limits or technical safeguards.</li>
                </ul>
              </li>
              <li>Enforcement:
                <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
                  <li>Suspending, limiting, or revoking access for violations of these Terms.</li>
                  <li>Taking legal action against individuals or entities involved in prohibited activities or misuse.</li>
                </ul>
              </li>
            </ul>

            <h3 className="font-semibold mb-2">3.5 No Guarantee of Performance</h3>

            <p className="leading-relaxed mb-4">The Playground is provided "as is" and "as available." Sqwish makes no guarantees regarding:</p>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>Service Availability: The availability, performance, or reliability of the Playground.</li>
              <li>Accuracy of Outputs: The uniqueness, accuracy, or fitness of the outputs for any particular purpose.</li>
            </ul>

            <h3 className="font-semibold mb-2">3.6 User Responsibilities</h3>

            <p className="leading-relaxed mb-4">As a user of the Playground, you are responsible for:</p>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>Compliance: Ensuring your use complies with these Terms and all applicable laws.</li>
              <li>Respect for Others: Refraining from behavior that harms or infringes on the rights of others, including abusive or harassing conduct.</li>
              <li>Backup and Data Retention: Independently storing any necessary inputs or outputs, as Sqwish is not responsible for retaining your data.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">4. Data Ownership and Rights</h2>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed">
              <li>Your Content: You retain ownership of any content you provide to the Playground, including inputs and generated outputs. However, by using the Playground, you grant Sqwish a worldwide, royalty-free, irrevocable license to use and create derivative works based on your content for the purposes of improving our models, services, and products.</li>
              <li>No Guarantee of Unique Outputs: While you retain rights to the outputs generated from your inputs, Sqwish does not guarantee that outputs will be unique or free from duplication. Generated outputs may be similar to or identical to outputs provided to other users based on comparable inputs.</li>
              <li>Retention of Data: Data submitted to or generated by the Playground will be retained for the duration necessary to fulfill the purposes outlined in these Terms, or as required by law. Data may also be retained to address system security or operational needs.</li>
              <li>Intellectual Property Rights of Sqwish: All intellectual property rights associated with the Playground, including its design, algorithms, features, and functionalities, remain the exclusive property of Sqwish and its licensors. Your use of the Playground does not grant you any rights to these intellectual properties beyond what is explicitly permitted under these Terms.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">5. Termination, Suspension, and Access Limitations</h2>

            <p className="leading-relaxed mb-4">We reserve the right to revoke, suspend, or limit access to the Playground at our sole discretion under the following conditions, recognizing that the Playground is a free service provided without user accounts.</p>

            <h3 className="font-semibold mb-2">5.1 Grounds for Termination, Suspension, or Limitations</h3>

            <p className='leading-relaxed mb-4'>Access to the Playground may be revoked, suspended, or subject to limitations (e.g., rate limits or restricted features) without notice if:
            </p>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>Excessive Usage: Your usage exceeds reasonable levels or imposes a disproportionate load on our systems.</li>
              <li>Violation of Terms: You engage in prohibited activities outlined in these Terms or violate applicable laws or regulations.</li>
              <li>Malicious Activity: Your actions compromise the security, functionality, or stability of the Playground.</li>
              <li>Operational Necessity: Suspension or limitation is required for maintenance, updates, or other operational needs.</li>
              <li>Discretionary Changes: We determine, at our sole discretion, that restricting or terminating access is necessary or beneficial to the operation of the Playground.</li>
            </ul>

            <h3 className="font-semibold mb-2">5.2 Access Limitations</h3>

            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>Rate Limits: We may implement rate limits or usage caps based on IP address or other identifiers to ensure fair access and system stability.</li>
              <li>Geographic Restrictions: Access may be restricted or denied in certain locations as deemed necessary by Sqwish.</li>
              <li>Temporary Suspension: Access may be temporarily suspended during maintenance or due to system updates.</li>
            </ul>

            <h3 className="font-semibold mb-2">5.3 No Right to Compensation or Reinstatement</h3>

            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>No Guarantees: Access to the Playground is provided "as is" with no guarantee of availability, performance, or continuity.</li>
              <li>No Compensation: As the Playground is offered free of charge, you acknowledge that you have no right to request compensation, damages, or reinstatement if your access is revoked, suspended, or limited.</li>
              <li>No Obligations: We are under no obligation to provide notice, explanation, or alternative access methods if your usage is restricted or terminated.</li>
            </ul>

            <h3 className="font-semibold mb-2">5.4 Permanent Discontinuation</h3>

            <p className="leading-relaxed mb-4">We reserve the right to permanently discontinue the Playground at any time, for any reason, without prior notice. Users will have no right to contest or seek recourse for such discontinuation.</p>

            <h3 className="font-semibold mb-2">5.5 Feedback and Appeals</h3>

            <p className="leading-relaxed mb-4">While we are not obligated to respond to requests or appeals, you may contact us at <a href={`mailto:${contactEmail}`} className="underline text-blue-600 hover:text-blue-800">{contactEmail}</a> to provide feedback or inquire about restrictions. Such inquiries do not guarantee reinstatement or changes to imposed limitations.</p>

            <h3 className="font-semibold mb-2">5.6 Retained Rights</h3>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed">
              <li>System Integrity: We retain the right to take any measures necessary to protect the integrity, security, and availability of the Playground.</li>
              <li>Data Usage: Any data submitted through the Playground prior to access termination may be retained and used as outlined in these Terms, subject to applicable laws.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">6. Disclaimers and Limitations of Liability</h2>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed">
              <li>The Playground is provided "as is" without warranties of any kind, express or implied, including but not limited to merchantability, fitness for a particular purpose, or non-infringement.</li>
              <li>To the extent permitted by law, we disclaim all liability for indirect, incidental, or consequential damages arising from your use of the Playground, including but not limited to loss of data, revenue, or anticipated profits.</li>
              <li>Our total liability for any claims related to your use of the Playground, regardless of the cause or form of action, will not exceed $1, reflecting the free nature of this service.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">7. User Rights</h2>
            <p className="leading-relaxed">
              If you are located in jurisdictions with data protection laws, you have rights such as accessing, correcting, deleting, or restricting processing of your data. Contact <a href={`mailto:${contactEmail}`} className="underline text-blue-600 hover:text-blue-800">{contactEmail}</a> to exercise these rights.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">8. Changes to These Terms</h2>
            <p className="leading-relaxed mb-4">
              We may update these Terms periodically to reflect changes in our services, applicable laws, or business practices.
            </p>

            <h3 className="font-semibold mb-2">8.1 Notification of Changes</h3>

            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>When these Terms are updated, we will provide notice by:</li>
              <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
                <li>Posting the updated Terms on the Playground with a revised "Effective Date."</li>
                <li>For significant or material changes, we may also notify users through other reasonable means, such as an announcement on the Playground or via email (if contact information is available).</li>
              </ul>
            </ul>

            <h3 className="font-semibold mb-2">8.2 Acceptance of Changes</h3>

            <p className="leading-relaxed mb-4">
              <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
                <li>By continuing to access or use the Playground after the updated Terms become effective, you agree to be bound by the updated Terms.</li>
                <li>If you do not agree to the updated Terms, you must discontinue your use of the Playground.</li>
              </ul>
            </p>

            <h3 className="font-semibold mb-2">8.3 Material Changes</h3>

            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>A material change includes modifications that significantly affect your rights, obligations, or the terms under which you use the Playground.</li>
              <li>For material changes, we may provide additional notice and allow for a reasonable review period before the changes take effect.</li>
            </ul>

            <h3 className="font-semibold mb-2">8.4 Effective Date</h3>

            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>All changes to these Terms will be effective as of the "Effective Date" indicated at the top of the updated Terms unless otherwise specified.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">9. Governing Law and Disputes</h2>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed">
              <li> Governing Law: These Terms and any disputes arising from or related to your use of the Playground shall be governed by the laws of England and Wales, except where the laws of your jurisdiction mandate otherwise.</li>
              <li>Dispute Resolution:
                <ul className="list-disc pl-6 space-y-2 leading-relaxed">
                  <li>For disputes where jurisdictional considerations apply, we reserve the right to select the jurisdiction most appropriate for resolving the matter, subject to applicable law.</li>
                  <li>If both parties agree, disputes may also be resolved through alternative dispute resolution mechanisms, such as arbitration or mediation, in a mutually agreeable location.</li>
                </ul>
              </li>
              <li>Jurisdiction: Any legal actions related to these Terms will be brought in the courts of England and Wales, unless another jurisdiction is deemed more appropriate by Sqwish.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">10. Contact Us</h2>
            <p className="leading-relaxed">
              For questions or concerns, contact us at <a href={`mailto:${contactEmail}`} className="underline text-blue-600 hover:text-blue-800">{contactEmail}</a>.
            </p>
          </section>
        </main>
      </div>
    </div>
  );
};

export default PlaygroundTermsAndConditions;