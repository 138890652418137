import React from 'react';

// Components
import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeaturesSection';
import PromptCompressionSection from './components/PromptCompressionSection';
import CostsSection from './components/CostsSection';
import CodeSampleSection from './components/CodeSampleSection';
import FAQSection from './components/FAQSection';
import Footer from '../Footer';

const HomePage: React.FC = () => {
  return (
    <div className="font-geist">
      <HeroSection />
      <FeaturesSection />
      <PromptCompressionSection />
      <CostsSection />
      <CodeSampleSection />
      <FAQSection />
      <Footer />
    </div>
  );
};

export default HomePage;