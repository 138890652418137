import React from 'react';
import plusIcon from '../../assets/icons/plus-icon.svg';
import minusIcon from '../../assets/icons/minus-icon.svg';

interface FAQItemProps {
  question: string;
  answer: string;
  id: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer, id }) => {
  return (
    <div className="flex flex-col justify-between bg-soft-blue rounded-[1.25rem] shadow-[inset_0_2px_4px_rgba(255,255,255,0.1),0_4px_8px_rgba(0,0,0,0.5)]">
      <input
        type="checkbox"
        id={id}
        className="collapsible-checkbox peer/collapsible hidden"
        name={id}
      />
      <label
        htmlFor={id}
        className="flex items-center cursor-pointer py-4 lg:py-6 pl-6 lg:pl-8 pr-4 lg:pr-6"
      >
        <p className="flex-grow text-black s-body" style={{ fontWeight: 430 }}>{question}</p>
        <img src={plusIcon} alt="Expand" className="plus-icon w-6 h-6" />
        <img src={minusIcon} alt="Collapse" className="minus-icon w-6 h-6" />
      </label>
      <p className="overflow-hidden max-h-0 pl-6 lg:pl-8 pr-4 lg:pr-6 peer-checked/collapsible:max-h-[30rem] peer-checked/collapsible:pb-4 peer-checked/collapsible:lg:pb-6 transition-all duration-300 ease-in-out s-body text-black/60 col-span-full font-light">
        {answer}
      </p>
    </div>
  );
};

export default FAQItem;