import React from 'react';
import arrowUpRightWhite from './assets/icons/arrow-up-right-white.svg';
import footerLogo from './assets/logo/sqwish-black-off-thick-high-res.webp';
import octopusCoding from './assets/octopus/octopus-coding.webp';
import { Link } from 'react-router-dom';
import { accessClick } from './analytics';

const Footer: React.FC = () => {
  const handleAccessLinkClick = () => {
    accessClick('footer');
  };

  return (
    <footer className="section">
      <div className="relative w-full flex flex-col pt-8 lg:pt-12 rounded-t-3xl lg:rounded-t-[2.5rem] bg-black overflow-hidden -mt-8 lg:-mt-10">
        <div className="flex flex-col w-full pb-4 lg:pb-16 gap-4">
          <div className="relative z-[1] mono-s flex max-lg:flex-col gap-6 text-white/80 px-4 lg:px-16">
            <span className="mr-auto flex flex-col justify-center items-center sm:flex-row ml-0">
              <p>© {new Date().getFullYear()} Sqwish. All rights reserved.</p>
            </span>
            <nav>
              <ul className="flex max-lg:flex-col max-lg:items-start gap-4 lg:gap-6 flex-wrap mb-0 pb-0">
                <li>
                  <Link
                    to="/access"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleAccessLinkClick}
                    className="flex gap-2 items-center hover:text-white border-b border-b-transparent hover:border-b-white"
                  >
                    <span className="border-b border-b-transparent border-b-white/50">
                      Request Access
                    </span>
                    <img
                      src={arrowUpRightWhite}
                      alt="Arrow right icon"
                      className="w-4 h-4"
                      width="16"
                      height="16"
                    />
                  </Link>
                </li>
                <li className="">
                  <a
                    href="mailto:contact@sqwish.ai"
                    className="flex gap-2 items-center hover:text-white border-b border-b-transparent hover:border-b-white"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="border-b border-b-transparent border-b-white/50">
                      Contact us
                    </span>
                    <img
                      src={arrowUpRightWhite}
                      alt="Arrow right icon"
                      className="w-4 h-4"
                      width="16"
                      height="16"
                    />
                  </a>
                </li>
                <li className="hidden lg:flex">
                  <img
                    src={octopusCoding}
                    alt="octopus-exploring"
                    className="w-auto h-[45px] -translate-y-[6px]"
                  />
                </li>
              </ul>
            </nav>
          </div>
          <nav>
            <ul className="flex flex-col items-start gap-4 lg:gap-6 flex-wrap mono-s text-white/80 px-4 lg:px-16">
              <li>
                <Link
                  to="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex gap-2 items-center hover:text-white border-b border-b-transparent hover:border-b-white"
                >
                  <span className="border-b border-b-transparent border-b-white/50">
                    Privacy Policy
                  </span>
                  <img
                    src={arrowUpRightWhite}
                    alt="Arrow right icon"
                    className="w-4 h-4"
                    width="16"
                    height="16"
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex gap-2 items-center hover:text-white border-b border-b-transparent hover:border-b-white"
                >
                  <span className="border-b border-b-transparent border-b-white/50">
                    Terms of Service
                  </span>
                  <img
                    src={arrowUpRightWhite}
                    alt="Arrow right icon"
                    className="w-4 h-4"
                    width="16"
                    height="16"
                  />
                </Link>
              </li>
              <li className="mt-2 lg:hidden">
                <img
                  src={octopusCoding}
                  alt="octopus-exploring"
                  className="w-auto h-[45px] -translate-y-[6px]"
                />
              </li>
            </ul>
          </nav>
        </div>
        <div className="w-full h-auto flex">
          <img
            src={footerLogo}
            alt="footer"
            className="h-auto opacity-60 pt-8 w-full max-w-[902px]"
          />
        </div>
      </div>
    </footer>
  );
};
export default Footer;
