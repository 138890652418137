import React from "react";
import SqwishLogo from "../assets/logo/sqwish-white-thick.webp";
import CheckMark from './CheckMark';
import { accessClick } from "../analytics";

type PricingVariant = 'free' | 'pro' | 'enterprise';

interface PricingCardProps {
  title: string;
  description: string;
  features: string[];
  variant: PricingVariant;
  isHighlighted: boolean;
  onHover: (variant: PricingVariant) => void;
  style?: React.CSSProperties;
  className?: string;
}

const variantDescriptions: Record<PricingVariant, string> = {
  free: "Get immediate access:",
  pro: "For those that want more:",
  enterprise: "Everything in Pro, plus:",
};

const PricingCard: React.FC<PricingCardProps> = ({
  title,
  description,
  features,
  variant,
  isHighlighted,
  onHover,
  style,
  className,
}) => {
  const getBackgroundColor = () => {
    switch (variant) {
      case "free":
        return "bg-pink";
      case "pro":
        return "bg-green";
      case "enterprise":
        return "bg-yellow";
    }
  };

  const getButtonStyle = () => {
    switch (variant) {
      case "free":
        return "bg-light-pink hover:bg-light-pink";
      case "pro":
        return "bg-light-green hover:bg-light-green";
      case "enterprise":
        return "bg-light-yellow hover:bg-light-yellow";
    }
  };

  const renderTitleWithLogo = (title: string) => {
    const parts = title.split(/(sqwish)/i);
    return (
      <div className="flex flex-col sm:flex-row sm:items-center gap-2">
        {parts.map((part, index) =>
          part.toLowerCase() === "sqwish" ? (
            <img
              key={index}
              src={SqwishLogo}
              alt="Sqwish Logo"
              className="w-24 h-auto"
            />
          ) : (
            <span key={index}>{part}</span>
          )
        )}
      </div>
    );
  };

  const handleButtonClick = () => {
    if (variant === "free") {
      accessClick('pricing-card');
      window.open('/access', '_blank', 'noopener,noreferrer');
    } else {
      window.location.href = 'mailto:contact@sqwish.ai';
    }
  };

  return (
    <div
      onMouseEnter={() => onHover(variant)}
      className={`
        rounded-3xl p-8 transition-all duration-300 hover:scale-[1.02]
        ${getBackgroundColor()}
        ${className}
        max-w-sm mx-auto
        ${isHighlighted ? 'shadow-[inset_0_3px_5px_rgba(255,255,255,0.2),3px_8px_10px_rgba(0,0,0,0.6)]' : ''}
      `}
      style={style}
    >
      <div className="space-y-6">
        <div>
          <h3 className="text-xl sm:text-2xl font-semibold">
            {renderTitleWithLogo(title)}
          </h3>
          <p className="mt-2 text-gray-800 text-sm sm:text-base">
            {description}
          </p>
        </div>

        <button
          className={`w-full rounded-full px-6 py-3 text-sm font-semibold transition-colors duration-200 shadow-md text-black ${getButtonStyle()}`}
          onClick={handleButtonClick}
        >
          {variant === "free" ? "Get access" : "Contact us"}
        </button>

        <ul className="space-y-3">
          <li key={0} className="flex items-center gap-3">
            <span className="text-sm text-gray-800">{variantDescriptions[variant]}</span>
          </li>

          {features.map((feature, index) => (
            <li key={index} className="flex items-center gap-3">
              <CheckMark className="h-5 w-5 text-gray-800" />
              <span className="text-sm text-gray-800">{feature}</span>
            </li>
          ))}
          {variant === "pro" && (
            <li key="cost-info" className="flex flex-col gap-2 pt-4 w-full">
              <hr className="w-full border-black mb-2" />
              <div className="flex justify-between items-center w-full">
                <span className="text-lg font-medium text-gray-800">Cost:</span>
                <span className="text-lg font-bold text-gray-900">20% of the savings</span>
              </div>
              <p className="text-xs text-gray-600 -mb-2 text-right -mt-2">
                (min $20/month)
              </p>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default PricingCard;
