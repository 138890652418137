// frontend/src/api.ts

import { fetchEventSource } from '@microsoft/fetch-event-source';

// Non-streaming approach (unchanged):
export const llm_inference = async (
    input_text: string,
    model: string
): Promise<{
    response: string;
    throughput: number;
    TTFT: number;
    total_latency: number;
    number_tokens: number;
}> => {
    const response = await fetch('/api/v1/llm_inference', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ input_text, model }),
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
};

export const sqwish = async (
    input_text: string
): Promise<{
    response: string;
    number_tokens: number;
    total_latency: number;
    throughput: number;
}> => {
    const response = await fetch('/api/v1/sqwish', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ input_text }),
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
};

export const tokenize = async (
    input_text: string,
    model: string
): Promise<{ tokens_count: number }> => {
    const response = await fetch('/api/v1/tokenize', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ input_text, model }),
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
};

/**
 * NEW: SSE-based streaming approach for partial results.
 * Streams chunk-by-chunk from /api/v1/llm_inference (with stream=True).
 */
export const llm_inference_stream = async (
    input_text: string,
    model: string,
    onToken: (partialContent: string) => void,
    onDone: (fullContent: string, finalStats: any) => void,
    onError: (err: any) => void
): Promise<void> => {
    let accumulatedText = '';
    let finalStats: any = null;
    const controller = new AbortController();

    await fetchEventSource('/api/v1/llm_inference', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'text/event-stream',
        },
        body: JSON.stringify({ input_text, model, stream: true }),
        signal: controller.signal,
        openWhenHidden: false,
        onmessage(ev) {
            if (ev.event === 'complete') {
                onDone(accumulatedText, finalStats);
                controller.abort();
                return;
            }

            if (!ev.data) return;

            try {
                const parsed = JSON.parse(ev.data);
                if (typeof parsed.content !== 'string') return;

                if (parsed.content === "") {
                    finalStats = parsed.stats || finalStats;
                    return;
                }

                accumulatedText += parsed.content;
                onToken(accumulatedText);
                finalStats = parsed.stats;
            } catch (err) {
                console.error('Error parsing SSE chunk:', err);
            }
        },
        onerror(err) {
            onError(err);
            controller.abort();
        },
        onclose() {
            onDone(accumulatedText, finalStats);
        },
    });
};