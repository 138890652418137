import React, { useState } from "react";
import PricingCard from "./PricingCard";
import overlay from '../assets/backgrounds/noise_overlay-7.webp';
import PricingTable from "./PricingTable";
import Footer from "../Footer";
import { Link } from 'react-router-dom';
import { accessClick } from "../analytics";

const PricingPage: React.FC = () => {
  const [highlightedVariant, setHighlightedVariant] = useState<"free" | "pro" | "enterprise">("pro");

  const pricingData = [
    {
      title: "Free",
      description: "Access the base open-source Sqwish model.",
      features: [
        "Self-hosted, open-source solution",
        "Not model specific", // One model that works for all
        "Community-driven updates"
      ],
      variant: "free" as const,
    },
    {
      title: "sqwish Pro",
      description: "Pay-as-you-save with managed hosting and support.",
      features: [
        // "Pay 20% of what you save - min $20/mo",
        "API access", // and three lines of code integrations
        "Model specific compression", // Custom compression for each llms / Specialized compression for different LLMs
        "Latest models integration and updates", //Always up-to-date with the latest LLMs / Regular updates and new model integration
        "Dashboard & reporting",
        "Customer support"
      ],
      variant: "pro" as const,
    },
    {
      title: "sqwish Enterprise",
      description: "Tailored for large-scale, mission-critical deployments.",
      features: [
        "Volume discounts",
        "PVC deployment option",
        "SLA-backed guarantees",
        "Fine-tuned custom models",
        "Priority customer support",
        "No rate-limits"
      ],
      variant: "enterprise" as const,
    },
  ];

  const handleHover = (variant: "free" | "pro" | "enterprise") => {
    if (variant !== "free") {
      setHighlightedVariant(variant);
    }
  };

  const handleSignupLinkClick = () => {
    accessClick('pricing-table');
  };

  return (
    <div className="h-full pt-24 pb-28 relative">
      <div className="fixed inset-0 overflow-hidden bg-grey -z-10">
        <img
          src={overlay}
          alt="background"
          className="w-full h-full object-cover object-center fixed -z-10"
        />
      </div>
      <div className="w-full">
        <div className="w-[90%] max-w-7xl mx-auto pb-[66px]">
          <div className="mt-4 max-w-3xl w-full mx-auto flex-col items-center text-center">
            <div className="w-full max-w-[55rem] mx-auto flex flex-col items-center text-center gap-6 px-4">
              <h3 className="text-black s-body">SIMPLE AND FLEXIBLE</h3>
              <h2 className="text-black heading-2 font-bold w-full max-w-[50rem]">Choose a plan</h2>
              <p className="text-black l-body w-full max-w-[48rem]">
                We only charge you for what we <strong className="italic">save</strong> you.
              </p>
            </div>
          </div>

          <div className="mt-12 mb-6 grid gap-10 sm:grid-cols-1 lg:grid-cols-3 px-4 lg:items-start">
            {pricingData.map((plan, index) => (
              <PricingCard
                key={index}
                {...plan}
                isHighlighted={highlightedVariant === plan.variant && plan.variant !== "free"}
                onHover={handleHover}
                className="animate-fade-up"
                style={{
                  animationDelay: `${index * 100}ms`,
                }}
              />
            ))}
          </div>
        </div>

        <section className="section bg-blue pt-8 px-6 pb-28 lg:pb-32 gap-4 lg:px-20 flex flex-col -mt-8 lg:-mt-8 items-center rounded-t-3xl lg:rounded-t-[2.5rem] relative">
          <div className="mt-16 max-w-4xl w-full mx-auto flex-col items-center text-center mb-4 lg:mb-4 px-4">
            <div className="w-full max-w-[55rem] mx-auto flex flex-col items-center text-center gap-6">
              <h3 className="text-black s-body">FEATURES THAT FIT YOUR NEEDS</h3>
              <h2 className="text-black heading-2 font-bold w-full max-w-[50rem]">Compare all features</h2>
              <p className="text-black l-body w-full max-w-[48rem]">
                Sqwish knows what's important to you: we retain critical information and keep structured text, code and tables intact.
              </p>
            </div>
          </div>
          <PricingTable />

          <div className="flex flex-col sm:flex-row items-center justify-center lg:gap-8 gap-4 m-body pt-12 pb-2 relative w-full px-4">
            <a
              href="mailto:contact@sqwish.ai"
              className="shadow-[inset_0_2px_4px_rgba(255,255,255,0.05),0_4px_8px_rgba(0,0,0,0.2)] m-body text-sm sm:text-base px-2 py-1 rounded-full bg-medium-blue hover:bg-soft-blue transition-colors duration-300 text-black h-[50px] w-full max-w-[200px] flex items-center justify-center"
              rel="noopener noreferrer"
            >
              Contact us
            </a>
            <Link
              to="/access"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleSignupLinkClick}
              className="shadow-[inset_0_2px_4px_rgba(255,255,255,0.05),0_4px_8px_rgba(0,0,0,0.3)] m-body text-sm sm:text-base px-2 py-1 rounded-full bg-black hover:bg-black/60 transition-colors duration-300 text-white h-[50px] w-full max-w-[200px] flex items-center justify-center"
            >
              Get access
            </Link>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default PricingPage;
