import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Styles
import './styles/main.css';
import './styles/highlighter-theme.css';
import './styles/preview-stack.css';
import './styles/stack.css';
import './styles/tailwind.css';
import './styles/scrolling.css';
// Components

import Playground from './playground/Playground';
import HomePage from './homepage/HomePage';
import RequestAccess from './access/RequestAccess';
import Header from './Header';
import Docs from './docs/Docs';
import PricingPage from './pricing/PricingPage';
import TermsOfService from './policies/TermsOfService';
import PrivacyPolicy from './policies/PrivacyPolicy';
import PlaygroundTermsAndConditions from './playground/PlaygroundTermsAndConditions';
import Tracker from '@openreplay/tracker';

const tracker = new Tracker({
  projectKey: process.env.REACT_APP_OPENREPLAY_PROJECT_KEY || "",
  __DISABLE_SECURE_MODE: process.env.REACT_APP_OPENREPLAY_DISABLE_SECURE_MODE === "true"
});

const App: React.FC = () => {
  useEffect(() => {
    tracker.start();
  }, [])
  return (
    <Router>
      <Header />
      <main role="main" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/playground" element={<Playground />} />
          <Route path="/playground/terms-and-conditions" element={<PlaygroundTermsAndConditions />} />
          <Route path="/access" element={<RequestAccess />} />
          <Route path="/docs" element={<Docs />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </main>
    </Router>
  );
};

export default App;