import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo2 from './images/playground-sqwish-2.webp'; // Import the logo

interface AcceptModalProps {
  onAccept: () => void;
}

const AcceptModal: React.FC<AcceptModalProps> = ({ onAccept }) => {
  const navigate = useNavigate();

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background Overlay */}
      <div className="absolute inset-0 bg-black/40"></div>
      {/* Modal Box */}
      <div className="relative z-60 w-11/12 max-w-md bg-white rounded-3xl shadow-lg p-6 mx-auto">
        <div className='flex flex-row mb-4 items-center'>
          <img src={logo2} alt="Sqwish" className='h-[54px]' />
        </div>
        <p className="mb-4 text-gray-700 text-[15px]">
          Before getting started, please review our <a href="/playground/terms-and-conditions" className="underline">Terms and Conditions</a>.
          By clicking "Accept" or continuing to use the Playground, you acknowledge that you have read, understood and agree to these terms.
        </p>
        <div className="flex gap-4 justify-end pt-2">
          <button
            className="py-2 px-4 bg-gray-200 rounded-[20px] hover:bg-gray-300 text-black hover:shadow text-sm"
            onClick={() => {
              // User declined -> go back to home
              navigate('/');
            }}
          >
            Back to home
          </button>
          <button
            className="py-2 px-4 bg-neutral-900 hover:bg-black/70 text-white rounded-[20px] shadow-lg hover:shadow-xl text-sm"
            onClick={onAccept}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default AcceptModal;