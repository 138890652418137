import React from 'react';
import overlay from '../assets/backgrounds/noise_overlay-7.webp';

const PrivacyPolicy: React.FC = () => {
  const effectiveDate = 'December 16, 2024';
  const contactEmail = 'contact@sqwish.ai';

  return (
    <div className="text-gray-800 font-sans relative bg-gray-50 min-h-screen">
      {/* Background overlay image */}
      <div
        className="pointer-events-none absolute inset-0"
        style={{
          backgroundImage: `url(${overlay})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundBlendMode: 'overlay',
        }}
      />
      <div className="w-full h-full overflow-y-auto">
        <main className="max-w-3xl mx-auto py-10 px-6 mt-20 relative z-10">
          {/* Main Title */}
          <h1 className="text-4xl font-bold mb-2">Privacy Policy</h1>

          {/* Last Updated / Effective Date */}
          <p className="text-sm text-gray-600 italic mb-8">
            Effective Date: {effectiveDate}
          </p>

          {/* Intro Paragraph */}
          <p className="text-base leading-relaxed mb-6">
            Sqwish.ai (<em>"we," "our,"</em> or <em>"us"</em>) is committed to protecting your privacy.
            This Privacy Policy explains how we collect, use, and disclose personal information when you
            interact with our website, API, or other services (collectively, the <em>"Services"</em>).
            If you have any questions, please contact us at <a href={`mailto:${contactEmail}`} className="underline text-blue-600 hover:text-blue-800">{contactEmail}</a>.
          </p>

          <hr className="my-8 border-gray-200" />

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
            <p className="leading-relaxed mb-4">
              We collect personal and non-personal data to provide and enhance our Services.
            </p>
            <h3 className="font-semibold mb-2">1.1 Information You Provide Directly</h3>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>
                <strong>Account Information:</strong> When you register or contact us, we may collect
                your name, email, company name, and billing details.
              </li>
              <li>
                <strong>Communications:</strong> Any correspondence or requests sent to us may be stored
                to address your inquiries.
              </li>
            </ul>

            <h3 className="font-semibold mb-2">1.2 Information We Collect Automatically</h3>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>
                <strong>Technical Data:</strong> Including your IP address, browser type, operating system,
                timestamps, and usage patterns when interacting with our Services.
              </li>
              <li>
                <strong>Cookies and Similar Technologies:</strong> To personalize your experience, analyze
                usage, and improve functionality.
              </li>
            </ul>

            <h3 className="font-semibold mb-2">1.3 Data from Third Parties</h3>
            <p className="leading-relaxed">
              We may receive information from partners, analytics tools, or publicly available sources
              to enhance our services.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
            <p className="leading-relaxed mb-4">
              We process your information for the following purposes:
            </p>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed">
              <li>To provide and improve the functionality of our Services.</li>
              <li>To respond to inquiries, technical issues, and support requests.</li>
              <li>To monitor service usage and ensure system security.</li>
              <li>To comply with legal obligations and enforce terms of service.</li>
              <li>For marketing communications (with your consent).</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">3. Data Logging and Special Requests</h2>
            <p className="leading-relaxed">
              By default, Sqwish.ai logs requests and API interactions to enhance service reliability
              and performance. However, you may request that your data is not logged by submitting a
              written request to <a href={`mailto:${contactEmail}`} className="underline text-blue-600 hover:text-blue-800">{contactEmail}</a>.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">4. Sharing Your Information</h2>
            <p className="leading-relaxed mb-4">
              We may share your information with:
            </p>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li><strong>Service Providers:</strong> Such as hosting, payment processors, and analytics providers.</li>
              <li><strong>Legal Authorities:</strong> When required by law or necessary to protect our rights.</li>
              <li><strong>Business Transfers:</strong> In the event of a merger or acquisition, your data may be transferred as part of the transaction.</li>
            </ul>
            <p className="leading-relaxed">
              We do not sell your personal information to third parties.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">5. International Data Transfers</h2>
            <p className="leading-relaxed">
              As a UK-based company, your data may be transferred to and stored in countries outside your
              region, including the United Kingdom or other jurisdictions. We implement appropriate
              safeguards, including Standard Contractual Clauses, to ensure data protection.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">6. Retention of Data</h2>
            <p className="leading-relaxed">
              We retain personal data only as long as necessary to fulfill its purpose, comply with legal
              obligations, or resolve disputes. For more information about retention periods, contact us
              at <a href={`mailto:${contactEmail}`} className="underline text-blue-600 hover:text-blue-800">{contactEmail}</a>.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">7. Your Rights</h2>
            <p className="leading-relaxed mb-4">
              Depending on your location, you may have rights under applicable laws, including:
            </p>
            <ul className="list-disc pl-6 space-y-2 leading-relaxed mb-4">
              <li>Accessing and rectifying your personal data.</li>
              <li>Requesting data deletion (subject to legal limitations).</li>
              <li>Objecting to data processing.</li>
              <li>Withdrawing consent (where applicable).</li>
              <li>Data portability.</li>
            </ul>
            <p className="leading-relaxed">
              You may exercise these rights by contacting <a href={`mailto:${contactEmail}`} className="underline text-blue-600 hover:text-blue-800">{contactEmail}</a>.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">8. Security Measures</h2>
            <p className="leading-relaxed">
              We implement technical and organizational measures to protect your information. However,
              no online platform is completely secure, and we encourage caution when sharing sensitive information.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">9. Use by Minors</h2>
            <p className="leading-relaxed">
              Our Services are not directed to individuals under 16. We do not knowingly collect data from minors.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">10. Updates to This Privacy Policy</h2>
            <p className="leading-relaxed">
              We may revise this policy to reflect changes in our practices, technology, or legal requirements.
              Updates will be published on this page with the effective date. Continued use of the Services
              indicates acceptance of any changes.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">11. Contact Us</h2>
            <p className="leading-relaxed">
              If you have any questions or concerns about this Privacy Policy or your data, please reach out
              to <a href={`mailto:${contactEmail}`} className="underline text-blue-600 hover:text-blue-800">{contactEmail}</a>.
            </p>
          </section>
        </main>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
