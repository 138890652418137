import React from 'react';

interface CheckMarkProps {
  variant?: 'ascii' | 'svg';
  className?: string;
}

const CheckMark: React.FC<CheckMarkProps> = ({ variant = 'svg', className = '' }) => {
  if (variant === 'ascii') {
    return <span className={className}>✔</span>;
  }

  return (
    <svg
      className={className}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Checkmark"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 13l4 4L19 7"
      />
    </svg>
  );
};

export default CheckMark;