export async function playgroundClick() {
  try {
    await fetch('/api/v1/click_playground', { method: 'POST' });
  } catch (error) {
    console.error('Error logging playground click', error);
  }
}

export async function acceptPlaygroundTermsClick() {
  try {
    await fetch('/api/v1/click_accept_playground_terms', { method: 'POST' });
  } catch (error) {
    console.error('Error logging playground click', error);
  }
}

export async function accessClick(source: string) {
  try {
    await fetch('/api/v1/click_access', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ source }),
    });
  } catch (error) {
    console.error('Error logging signup click', error);
  }
}

export async function pricingClick() {
  try {
    await fetch('/api/v1/click_pricing', { method: 'POST' });
  } catch (error) {
    console.error('Error logging pricing click', error);
  }
}
